$(function () {
    initNav();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            $nav.slideToggle();
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }
}